<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <label for="id_fornecedor">Filtrar por Fornecedor/Mês/Ano</label><br>
                <Dropdown filter id="id_fornecedor" required="true" v-model="id_fornecedor" :options="fornecedores"
                    optionLabel="label" optionValue="value" placeholder="Selecione um Fornecedor">
                </Dropdown>

                <Dropdown filter id="mes" required="true" v-model="mes" :options="meses" class="ml-3"
                    optionLabel="label" optionValue="value" placeholder="Selecione um Mês">
                </Dropdown>
                <Dropdown filter id="ano" required="true" v-model="ano" :options="anos" class="ml-3" optionLabel="label"
                    optionValue="value" placeholder="Selecione um Ano">
                </Dropdown>
                <Button class="ml-3" :loading="loading" @click="listarImportacoesContestadas()">Filtrar</Button>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-12">
            <div class="col-12 xl:col-12">
                <div class="card">
                    <h5>Importações Contestadas</h5>

                    <DataTable ref="rf" exportFilename="relatorio[Faturas Contestadas]" csvSeparator=";"
                        :value="dadosContestados" :stickyHeader="true" v-model:selection="selectedProducts" dataKey="id"
                        :paginator="true" :rows="10" :filters="filters"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[5, 10, 25]"
                        currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                        responsiveLayout="scroll">
                        <template #header>
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <h5 class="m-0">Dados Contestados - Compartilhamento</h5>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                </span>
                                <Button label="Exportar Excel" icon="pi pi-upload" class="p-button-success ml-5"
                                    @click="exportCSV2($event)" />
                            </div>
                        </template>

                        <Column v-for="col of cabecalho" :field="col.field" :header="col.header" :key="col.field"
                            style="white-space:nowrap">
                            <template #body="{ data }" v-if="col.field === 'acoes'">
                                <Button icon="pi pi-search" class="p-button-rounded p-button-info"
                                    @click="mostrarPlanilha(data)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>

            <Dialog v-model:visible="mostrarPlanilhaModal" :style="{ width: '80%' }" header="Planilha Fornecedor"
                :modal="true">
                <div>
                    <DataTable ref="rf" exportFilename="relatorio[Dados Detalhados Faturas Contestadas]"
                        csvSeparator=";" :value="dadosImportacaoContestada" :stickyHeader="true"
                        v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="10"
                        :filters="filters"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[5, 10, 25]"
                        currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                        responsiveLayout="scroll">
                        <template #header>
                            <div
                                class="flex flex-column md:flex-row md:justify-content-between md:align-items-center gap-2">
                                <div class="flex order-1 md:order-0">
                                    <h5 class="m-0">Dados detalhados da fatura contestada</h5>
                                </div>
                                <div
                                    class="flex order-0 md:order-1 flex-column md:flex-row md:align-items-center gap-2">
                                    <Button label="Aprovar" icon="pi pi-check" class="p-button-success"
                                        @click="aprovarContestada()" :disabled="modalDisabled" />
                                    <Button label="Recusar" icon="pi pi-times" class="p-button-danger ml-2"
                                        @click="recusarContestada()" :disabled="modalDisabled" />
                                </div>
                            </div>
                        </template>

                        <Column v-for="col of cabecalhoModal" :field="col.field" :header="col.header" :key="col.field"
                            style="white-space:nowrap"></Column>
                    </DataTable>
                </div>
            </Dialog>
        </div>
    </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            fornecedores: [],
            id_fornecedor: '',
            mes: '',
            ano: '',
            anos: [
                { label: "", value: "" },
                { label: "2021", value: "2021" },
                { label: "2022", value: "2022" },
                { label: "2023", value: "2023" },
                { label: "2024", value: "2024" },
                { label: "2025", value: "2025" }
            ],
            meses: [
                { label: "", value: "" },
                { label: "Janeiro", value: "01" },
                { label: "Fevereiro", value: "02" },
                { label: "Março", value: "03" },
                { label: "Abril", value: "04" },
                { label: "Maio", value: "05" },
                { label: "Junho", value: "06" },
                { label: "Julho", value: "07" },
                { label: "Agosto", value: "08" },
                { label: "Setembro", value: "09" },
                { label: "Outubro", value: "10" },
                { label: "Novembro", value: "11" },
                { label: "Dezembro", value: "12" },
            ],
            mostrarPlanilhaModal: false,
            planilhas: [],
            cabecalho: [
                { header: "ID Upload", field: "id_planilha_upload" },
                { header: "ID da Fatura", field: "id_fatura" },
                { header: "Fornecedor", field: "razao_social_fornecedor" },
                { header: "Motivo Contestação", field: "motivo_contestacao" },
                { header: "Status", field: "status" },
                { header: "Valor Fatura", field: "valor" },
                { header: "Ações", field: "acoes" },
            ],
            cabecalhoOperadora: [
                { header: "Site ID TIM", field: "site_id_tim" },
                { header: "END ID TIM", field: "end_id_tim" },
                { header: "Regional TIM", field: "regional_tim" },
                { header: "UF", field: "uf" },
                { header: "Detentora", field: "detentora" },
                { header: "Site ID Detentora", field: "site_id_detentora" },
                { header: "Nº SCI Inicial", field: "n_sci_inicial" },
                { header: "CC (kWh)", field: "cc_kwh" },
                { header: "CA (kWh)", field: "ca_kwh" },
                { header: "Climatização (kWh)", field: "climatizacao_kwh" },
                { header: "Energia Essencial (kWh)", field: "energia_essencial_kwh" },
                { header: "Total de Consumo (kWh)", field: "total_de_consumo_kwh" },
                { header: "Tarifa Utilizada Operadora", field: "tarifa_utilizada_operadora" },
                { header: "Valor Total", field: "valor_total" },
                { header: "Tipo Energia", field: "tipo_de_energia" },
                { header: "É viável instalação Medidor TIM", field: "viabilidade_instalacao_medidor_tim" },
                { header: "Justificativa", field: "justificativa" },
                { header: "Observação Detentora", field: "observacao_detentora" },
                { header: "Mês de Referência do Formulário", field: "mes_ref_formulario" },
                { header: "Data de Envio do Formulário", field: "data_envio_formulario" },
                { header: "Mês de Referência da Fatura", field: "mes_ref_fatura" },
                { header: "Valor total da Fatura", field: "valor_total_fatura" },
                { header: "Motivo Contestação", field: "motivos_contestacao" },

            ],
            cabecalhoTower: [
                { header: "Site ID TIM", field: "site_id_tim" },
                { header: "END ID TIM", field: "end_id_tim" },
                { header: "Regional TIM", field: "regional_tim" },
                { header: "UF", field: "uf" },
                { header: "Detentora", field: "detentora" },
                { header: "Site ID Detentora", field: "site_id_detentora" },
                { header: "Data RFI", field: "data_rfi" },
                { header: "Tipo Energia", field: "tipo_de_energia" },
                { header: "Consumo (kWh)", field: "consumo_kwh" },
                { header: "É viável instalação Medidor TIM", field: "viabilidade_instalacao_medidor_tim" },
                { header: "Justificativa", field: "justificativa" },
                { header: "Observação Detentora", field: "observacao_detentora" },
                { header: "Mês de Referência do Formulário", field: "mes_ref_formulario" },
                { header: "Data de Envio do Formulário", field: "data_envio_formulario" },
                { header: "Mês de Referência da Fatura", field: "mes_ref_fatura" },
                { header: "Valor total da Fatura", field: "valor_total_fatura" },
                { header: "Motivo Contestação", field: "motivos_contestacao" },

            ],
            empresas: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            empresa: {},
            selectedProducts: null,
            filters: {},
            submitted: false,
            status: 0,
            statuses: [
                { label: "Inativo", value: "0" },
                { label: "Ativo", value: "1" },
            ],
            dadosContestados: [],
            dadosImportacaoContestada: [],
            modalDisabled: false,
            id_fatura: null,
            id_planilha_upload: null,
            fornecedor: "",
            tipoUploadModal: "",
            valor_total_fatura: ""
        };
    },
    computed: {
        cabecalhoModal() {
            if (this.tipoUploadModal === "OPERADORA") {
                return this.cabecalhoOperadora;
            } else if (this.tipoUploadModal === "TOWER") {
                return this.cabecalhoTower;
            }
            return [];
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.listarPlanilhas();
        this.listarImportacoesContestadas();
        this.listarFornecedor();
    },
    methods: {
        mostrarPlanilha(rowData) {
            this.modalDisabled = rowData.status.toLowerCase() !== "em aberto";
            this.tipoUploadModal = rowData.tipo_upload;
            this.listarDadosImportacaoContestada(rowData.id_fatura, rowData.tipo_upload);
            this.mostrarPlanilhaModal = true;
            this.id_fatura = rowData.id_fatura;
            this.id_planilha_upload = rowData.id_planilha_upload;
            this.fornecedor = rowData.fornecedor;
        },
        listarRelatorio(id_planilha) {
            let dados = { id_planilha };
            this.axios
                .post("/relatorios/consolidado", dados)
                .then((response) => {
                    console.log(response);
                    this.empresas = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarPlanilhas() {
            this.axios
                .post("/relatorios/planilhas-importadas")
                .then((response) => {
                    console.log(response);
                    this.planilhas = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarImportacoesContestadas() {
            const dados = {
                id_fornecedor: this.id_fornecedor,
                mes: this.mes,
                ano: this.ano,
            };

            this.axios
                .post("/relatorios/importacoes-contestadas", dados)
                .then((response) => {
                    console.log(response);
                    this.dadosContestados = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarDadosImportacaoContestada(id_fatura, tipo_upload) {
            let dados = { id_fatura, tipo_upload };
            this.axios
                .post("/relatorios/dados-importacao-contestada", dados)
                .then((response) => {
                    console.log(response);
                    this.dadosImportacaoContestada = response.data.dados;
                    if (response.data.dados && response.data.dados.length > 0) {
                        this.valor_total_fatura = response.data.dados[0].valor_total_fatura;
                    }
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        createId() {
            let id = "";
            var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        exportCSV2() {
            this.$refs.rf.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        aprovarContestada() {
            this.loading = true;
            let dados = {
                id_fatura: this.id_fatura,
                id_planilha_upload: this.id_planilha_upload,
                fornecedor: this.fornecedor,
                tipo_upload: this.tipoUploadModal.toLowerCase(),
                valor_total_fatura: this.valor_total_fatura
            };
            this.axios
                .post("/relatorios/aprovar-pagamento-contestadas", dados)
                .then(() => {
                    this.listarRelatorioPorFornecedor();
                    this.loading = false;
                    this.mostrarPlanilhaModal = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        recusarContestada() {
            this.loading = true;
            let dados = {
                id_fatura: this.id_fatura,
                id_planilha_upload: this.id_planilha_upload,
                fornecedor: this.fornecedor,
                status: "recusada"
            };
            this.axios
                .post("/relatorios/recusar-pagamento-contestadas", dados)
                .then(() => {
                    this.listarRelatorioPorFornecedor();
                    this.loading = false;
                    this.mostrarPlanilhaModal = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        listarFornecedor() {
            this.axios
                .post("/fornecedores/listar")
                .then((response) => {
                    let options = [];
                    let desc_fornecedores = {};
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["razao_social"] };
                        desc_fornecedores[el["id"]] = el["razao_social"];
                        options.push(option);
                    });
                    this.fornecedores = options;
                    this.desc_fornecedores = desc_fornecedores;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>